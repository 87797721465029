import dotenv from 'dotenv'
dotenv.config()

export default class Configuration {
    static get CONFIG() {
        return {
            authUrl: '$VUE_APP_AUTH_BASE_URL',
            backendUrl: '$VUE_APP_API_BASE_URL',
            loginUrl: '$VUE_APP_LOGIN_URL',
            dashboardUrl: '$VUE_APP_DASHBOARD_BASE_URL',
            appBarColor: '$VUE_APP_BAR_COLOR',
            csrfCookieName: '$VUE_APP_CSRF_COOKIE_NAME',
        }
    }

    static value(name) {
        if (!(name in this.CONFIG)) {
            console.warn(`Configuration: no key named "${name}"`)
            return undefined
        }

        let value = this.CONFIG[name]

        if (!value) {
            return undefined
        }

        if (value.startsWith('$VUE_APP_')) {
            const envName = value.substr(1)
            value = process.env[envName] || value

            if (value.startsWith('$VUE_APP_')) {
                const fallbacks = {
                    VUE_APP_CSRF_COOKIE_NAME: 'XSRF-TOKEN',
                    // Add other fallbacks as needed
                }

                if (envName in fallbacks) {
                    console.warn(`Configuration: "${envName}" not defined in ENV vars, using fallback.`)
                    value = fallbacks[envName]
                } else {
                    console.warn(`Configuration: "${envName}" not defined and no fallback provided.`)
                    value = undefined
                }
            }
        }

        return value
    }
}
