import axios from "axios";
import Configuration from "@/services/configuration";
import {store} from "@/store/store";

const JOINEER_NAME = 'Joineer';

const httpAuthClient = axios.create({
    baseURL: Configuration.value('authUrl'),
    timeout: 20000,
    withCredentials: true
});

const CSRF_COOKIE_NAME = Configuration.value('csrfCookieName');

httpAuthClient.interceptors.response.use(response => {
    return response;
}, (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error?.response?.status !== 401) {
        return Promise.reject(error);
    }

    // Logout user if token refresh didn't work or user is disabled
    if (error.config.url.endsWith('/token')) {
        store.dispatch("auth/logout");
        window.location.href = Configuration.value('loginUrl');
    }
    return Promise.reject(error);

});

httpAuthClient.interceptors.request.use((config) => {
    // Read the token from the 'XSRF-TOKEN' cookie
    const token = getCookie(CSRF_COOKIE_NAME);
    if (token) {
        // By default, Spring expects the header name 'X-XSRF-TOKEN'
        config.headers['X-XSRF-TOKEN'] = token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

function getCookie(name) {
    // This regex captures the value of `name` from `document.cookie`.
    // If not found, it returns an empty string.
    const regex = new RegExp('(?:(?:^|.*;\\s*)' + name + '\\s*=\\s*([^;]*).*$)|^.*$');
    return document.cookie.replace(regex, '$1');
}


const END_POINT = '/api/v1';


const csrfHandshake = () => httpAuthClient.get(`${END_POINT}/csrf-token`);

const signin = (user:any) => httpAuthClient.post(`${END_POINT}/login`,{
    username: user.username,
    password: user.password
});

const token = () => httpAuthClient.post(`${END_POINT}/token`);

const profiles =  () => httpAuthClient.get(`${END_POINT}/user/profiles`);


const selectProfile = (membershipId:string) => httpAuthClient.post(`${END_POINT}/selectProfile`, {
    membershipId: membershipId
});


const logout = () => httpAuthClient.post(`/logout`);

const impersonate = (userId:string) => httpAuthClient.post(`${END_POINT}/admin/impersonate`,{
    targetUserId: userId
});

const stopImpersonate = () => httpAuthClient.post(`${END_POINT}/admin/impersonate/stop`);

export {
    csrfHandshake,
    logout,
    signin,
    token,
    impersonate,
    stopImpersonate,
    selectProfile,
    profiles,
    JOINEER_NAME
}
