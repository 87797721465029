import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: '/calendar',
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import("../views/Calendar.vue"),
    },
    {
        path: '/tutorial',
        name: 'tutorial',
        component: () => import("../views/TutorialForm.vue"),
    },
    {
        path: '/search',
        name: 'search',
        component: () => import("../views/Search.vue"),
    },
    {
        path: '/importData/:companyId',
        name: 'importData',
        component: () => import("../views/ImportData.vue"),
    },
    {
        path: '/company-search',
        name: 'company-search',
        component: () => import("../views/SearchCompany.vue"),
    },
    {
        path: '/import',
        name: 'import',
        component: () => import("../views/Import.vue"),
    },
    {
        path: '/restore',
        name: 'restore',
        component: () => import("../views/Restore.vue"),
    },
    {
        path: '/backup',
        name: 'backup',
        component: () => import("../views/Backup.vue"),
    },
    {
        path: '/new-company',
        name: 'company-create',
        component: () => import("../views/CreateCompany.vue")
    },
    {
        path: '/companies/:companyId',
        name: 'Company',
        component: () => import("../views/Company.vue"),
    },

    {
        path: '/surveys/:surveyId',
        name: 'Survey',
        component: () => import("../views/Survey.vue"),
    },
    {
        path: '/email',
        name: 'email',
        component: () => import("../views/DefaultEmailTemplates.vue"),
    },
    {
        path: '/templates/:companyId',
        name: 'Company templates',
        component: () => import("../views/CompanyTemplates.vue"),
    },
    {
        path: '/email/create',
        name: 'email-create',
        component: () => import("../views/CreateEmailTemplate.vue"),
        meta: {
            title: "Create e-mail template"
        }
    },

    {
        path: '/sms',
        name: 'SMS',
        component: () => import("../views/DefaultSMSTemplates.vue"),
        meta: {
            title: "SMS"
        }
    },
    {
        path: '/sms/create',
        name: 'sms-create',
        component: () => import("../views/CreateSMSTemplate.vue"),
        meta: {
            title: "Create SMS template"
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import("../views/Login.vue"),
        meta: {
            title: "Log in"
        }
    },
    {
        path: '/login-success',
        name: 'Login-success',
        component: () => import("../views/LoginSuccess.vue"),
        meta: {
            title: "Log in success"
        }
    },
    {
        path: '/cleanup',
        name: 'Cleanup',
        component: () => import("../views/Cleanup.vue"),

        meta: {
            title: "Clean up"
        }
    },


]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/login','/login-success'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router
